import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import moment from "moment";
import { user } from "./assets";
// import { notification, message as MESSAGE } from "antd";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../components/src/WithBrandingSpinner.web";
const configJSON = require("./config");

// Customizable Area End

export type Props = RouterProps &
  // Customizable Area Start
  withLoaderProps & {
    id: string;
    navigation: any;
    history?: any;
    match?: any;
    location?: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  Reset: boolean;
  SideNavBar: boolean;
  inputValue: any;
  sliderData: any;
  openModal: boolean;
  modalTitle: string;
  fullName: string | null;
  videoList: any;
  featureList: any;
  trandingList: any;
  dynamicId: any;
  searchList: any;
  searchItem: string;
  profileImageUrl: string;
  SearchKeyword: string;
  notificationData:any;
  pageNo:any;
  perPage:any,
  NoData:boolean;
  modalImage:string;
  token:string;
  loading:boolean;
  discoverList: any;
  apiNextPage:any;
  totalCount:any;
  visibleNotificationAction:boolean[];
  totalBadge:any;
  receivedBadge:any;
  notificationsSeen:boolean;
  notificationCount:any;
  isBell?:boolean;
  isShow?:boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  isUserLoggedIn: boolean;
  
  // Customizable Area End
}

interface ApiData {
  contentType: string; 
  method: string; 
  endPoint: string; 
  body?: Body; 
  type?: string;
}
interface Body {
  [key: string] : string | number[]
}



export default class HomePageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  
  getProfileApicall: string;
  getBadgeApicall: string;
  getVideoApicall : string;
  getFeaturedApicall: string;
  getDiscoverdApicall: string;
  getTrandingApicall: string;
  getNotificationData: string = "";
  clearNotificationData:string = "";
  getSearchApicall: string = "";
  getSearchSuggestionApiCall: string = "";
  timer: any;
  notificationsContainerRef: React.RefObject<unknown>;
  lastScrollTime = 0;
  setUserInformation: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage)
    ];
    this.notificationsContainerRef = React.createRef();
    this.getProfileApicall = ""
    this.getBadgeApicall = ""
    this.getVideoApicall = ""
    this.getFeaturedApicall = ""
    this.getTrandingApicall= ""
    this.getDiscoverdApicall= "";

  
    
    // Customizable Area End

    // Customizable Area Start
   
    this.state = {
      Reset: false,
      SideNavBar: false,
      inputValue: "0",
      sliderData: [1, 1, 1, 1, 1, 1, 1],
      openModal: false,
      modalTitle: "",
      fullName: "",
      videoList: null,
      featureList: [],
      trandingList:[],
      notificationData: [],
      dynamicId: "",
      searchItem: "",
      pageNo:1,
      perPage:10,
      NoData:false,
      searchList: null,
      profileImageUrl: "",
      SearchKeyword : "",
      modalImage: "",
      token: "",
      loading:false,
      discoverList: [],
      apiNextPage:0,
      totalCount:0,
      visibleNotificationAction: [],
      totalBadge:0,
      receivedBadge:0,
      notificationsSeen:false,
      notificationCount:0,
      isBell:false,
      isShow:true
    };

    
    // Customizable Area End

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }


  

  // Customizable Area Start
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiSuccessResponse(message);
      this.handleApiErrorResponse(message); 
    }

   
  }
  
  getApiCommonResponseDetail = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    return {apiRequestCallId, responseJson, errorReponse}
  }

  handleApiSuccessResponse = (message: Message) => {
    const {apiRequestCallId, responseJson, errorReponse} = this.getApiCommonResponseDetail(message);
    if (responseJson && !responseJson.errors && !errorReponse) {
      if (apiRequestCallId === this.getProfileApicall) {
        this.getProfileSuccessCallBack(responseJson.data)
      }
      if (apiRequestCallId === this.getBadgeApicall) {
        this.getBadgeSuccessCallBack(responseJson)
      }
      if (apiRequestCallId === this.getVideoApicall) {
        this.getVideoSuccessCallBack(responseJson.data)
      }
      if (apiRequestCallId === this.getFeaturedApicall) {
        this.getFeaturedSuccessCallBack(responseJson.data)
      }
      if (apiRequestCallId === this.getDiscoverdApicall) {
        this.getdiscoverSuccessCallBack(responseJson.data)
      }
      if (apiRequestCallId === this.getTrandingApicall) {
        this.getTrandingSuccessCallBack(responseJson.data)
      }
      if (apiRequestCallId === this.getSearchApicall) {
        console.log(responseJson.data, apiRequestCallId , "response aya" )
        this.getSearchSuccessCallBack(responseJson.data)
      }
      if (apiRequestCallId === this.getSearchSuggestionApiCall) {
        this.getSearchSuggestionSuccessCallBack(responseJson)
      }

      if (this.getNotificationData === apiRequestCallId) {
        this.getNotificationDataSuccessCallBack(responseJson)

     
      }

      if (this.clearNotificationData === apiRequestCallId) {
        this.getClearAllSuccessCallBack(responseJson)
     
      }

      if (this.setUserInformation === apiRequestCallId) {
      this.getLogoutSuccessCallBack(responseJson) 
      }

      
    }
  }

  handleApiErrorResponse = (message: Message) => {
    const {apiRequestCallId, responseJson, errorReponse} = this.getApiCommonResponseDetail(message);
    if (responseJson?.errors || !errorReponse?.errors) {
      if (apiRequestCallId === this.getProfileApicall) {
        this.getProfileFailureCallBack()
      }
      if (apiRequestCallId === this.getVideoApicall) {
        this.getVideoFailureCallBack()
      }
      if (apiRequestCallId === this.getFeaturedApicall) {
        this.getFeaturedFailureCallBack()
      }
      if (apiRequestCallId === this.getTrandingApicall) {
        this.getTrandingFailureCallBack()
      }
      if (apiRequestCallId === this.getSearchApicall) {
        console.log(errorReponse, "error ayi")
        this.getSearchFailureCallBack()
      }
      if (this.getNotificationData === apiRequestCallId) {
        console.log(errorReponse, "error ayi hai")
      }
      if (this.clearNotificationData === apiRequestCallId) {
        console.log(errorReponse, "error ayi hai")
      }
    }
  }

  getProfileFailureCallBack() {
    console.log("hello failure")
  }

  
  getVideoFailureCallBack() {
    console.log("video failure")
  }
  getFeaturedFailureCallBack() {
    console.log("Featured failure")
  }
  getTrandingFailureCallBack() {
    console.log("Tranding failure")
  }
  getSearchFailureCallBack() {
    console.log("search failure")
  }


  getFeaturedSuccessCallBack(data: any) {
    this.setState({featureList: data})
  }
  getdiscoverSuccessCallBack(data: any) {
    this.setState({discoverList: data})
  }
  getTrandingSuccessCallBack(data: any) {
    this.setState({trandingList: data, dynamicId: data[0].id})
  }

  timeFormat(notificationReceivedTime:any){
      const notificationTime = moment(notificationReceivedTime);
      const now = moment();
      const duration = moment.duration(now.diff(notificationTime));
    
      const years = duration.years();
      const months = duration.months();
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
    
      const timeUnits = ['year', 'month', 'day', 'hour', 'minute'];
      const timeValues = [years, months, days, hours, minutes];

      for (let i = 0; i < timeUnits.length; i++) {
        const unit = timeUnits[i];
        const value = timeValues[i];

        if (value > 0) {
          return `${value} ${unit}${value > 1 ? 's' : ''} ago`;
        }
      }

      return 'Just now';
      }

  getProfileSuccessCallBack(data: any){
    this.setState({fullName: `${data.attributes.first_name} ${data.attributes.last_name}`, profileImageUrl: `${data.attributes.profile_image_url ?? ""}`})
  }
    getBadgeSuccessCallBack(data: any){
    this.setState({totalBadge:data?.meta?.total_app_progression_Badges,receivedBadge:data?.meta?.app_badges_received }, ()=>{
    })
  }

 

  getSearchSuccessCallBack(data:any) {
    console.log("Search List data",data)
    this.setState({videoList:data})
  }
  getSearchSuggestionSuccessCallBack(data:any){
    console.log(data, "search list")
    this.setState({searchList:data})
    console.log(this.state.searchList , "Mock Search list")
  }



  navigateVideoPlayer = (video: any) => {
    console.log("Herloleoen")
    // this.props.history.push("/homePage")
  };

  
  
  getVideoSuccessCallBack(data: any) {
    console.log(data, "videolist")
    this.setState({videoList: data})
    
  }

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, type } = data
    let token = localStorage.getItem("token");
    const header = {
      'Content-Type': contentType,
      token,
    }
    const apiCallMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(apiCallMessage.id, apiCallMessage);
    return apiCallMessage.messageId;
  }


  showModal = () => {
    this.setState({
      SideNavBar: true
    });
  };

  gotologin = () => {
    this.props.navigation.navigate("/emailLogin")
  }

  handleCancelNav = (e: any) => {
    console.log(e);
    this.setState({
      SideNavBar: false
    });
  };
  handleClick = () => {
    this.setState({
      SideNavBar: false
    });
  };

  

 



  handleCancel = (e: any) => {
    this.setState({
      openModal: false,
      
    });
  };

  handleMenuClick = (menuName:string) => {
    const isLoginCheck = this.isLoginCheck()
    if (!isLoginCheck) {
      this.setState((prev) => ({...prev, openModal: true, modalTitle:menuName,modalImage:user}))
    }
  }


  isLoginCheck = () => {
    const isLoginCheck = localStorage.getItem("token")
    if (isLoginCheck) {
      return true;
    }
    return false;
  }

 
  onChange = (value: any) => {
    this.setState({
      inputValue: value
    });
  };
 
  RegisterSignUpSuccessCallBack = (res: any) => {
    console.log("@@@", res);
  };

  handleLogout = () => {
    this.setUserInfo()
  };


  setUserInfo = () => {
    let token = localStorage.getItem('token');
    const headers = {
      token: token,
    };

    const formData = new FormData();
    formData.append("data[attributes][is_primary]", "true");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setUserInformation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/update"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  notificationAPI = () => {
    if(this.state.pageNo == 1 ){
      this.setState({
        loading: true
      });
    } else {
      this.setState({
        loading: false
      });
    }
    
    let token = localStorage.getItem('token');
    const headers = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationData = requestMessage.messageId;
    if(token == null){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `push_notifications/push_notifications?guest=true&page=${this.state.pageNo}&per_page=${this.state.perPage}`
      );
    }else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `push_notifications/push_notifications?page=${this.state.pageNo}&per_page=${this.state.perPage}`
      );
    }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotificationDataSuccessCallBack(data:any){
  this.setState({
    loading: false
  });
  this.setState({
    apiNextPage: data?.meta?.pagination?.next_page == null ? 0 : data?.meta?.pagination?.next_page
  });
  let countString = localStorage.getItem("mainCount");
  let count = countString ? parseInt(countString) : 0;
  let totalCount = data?.meta?.pagination?.total_count || 0;
  totalCount -= count;
  
  if (isNaN(totalCount)) {
    // Handle the case when totalCount becomes NaN
    totalCount = 0; // Or any default value that makes sense in your context
  }
  
  this.setState({
    totalCount: totalCount
  });
  
  if (this.state.isBell) {
    localStorage.setItem('mainCount', data?.meta?.pagination?.total_count || 0);
    this.setState({
      isBell: false
    });
  } else {
    this.setState({
      isShow: true
    });
  }
  
   if (data) {
    if(data?.meta?.pagination?.total_count !== this.state.notificationData.length){
 
      this.setState((prevState) => ({
        notificationData: [...prevState.notificationData, ...data?.data],
      }));
      const nextPageNo = this.state.apiNextPage == 0 ? 1 : this.state.apiNextPage;
      
      this.setState({
        pageNo: nextPageNo,
      });
    }
    // else{
    //   this.setState((prevState) => ({
    //     notificationData: [...data?.data],
    //   }));
    // } 
  } else  {
    this.setState({
      NoData : true
    })
  } 
 
}

handleScroll = () => {
  if(this.state.totalCount !== this.state.notificationData.length){
  if(this.state.NoData === false){
    const currentTime = new Date().getTime();
  if (currentTime - this.lastScrollTime > 3000) {
    this.lastScrollTime = currentTime;
    const nextPageNo = this.state.apiNextPage == 0 ? 1 : this.state.apiNextPage;
     console.log(nextPageNo,"page No ")
     console.log(this.state.apiNextPage,"page No from API ")
    if(this.state.apiNextPage !== 1){
      this.setState({
        pageNo: nextPageNo,
      });
      this.notificationAPI(); 
    }else{
    console.log("Hello")
    }
  }else{
    // console.log("Hello")
  }
  }else{
    console.log("No Data Found")
  }
}else{
  console.log("No New Data Come")
}
  
  
};



clearAll = () => {
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    // Check if service worker is supported and has a controller
    navigator.serviceWorker.controller.postMessage({ action: 'logout' });
  } else {
    // Handle the case where there is no service worker controller
    console.warn('Service worker controller not found.');
  }
  
  let token = localStorage.getItem('token');
  const headers = {
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.clearNotificationData = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_push_notifications/update_to_read_all"
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}


getClearAllSuccessCallBack(data:any){
  this.setState({
    pageNo: 1
  });
  if(data?.message == "All notification updated to read all"){
    this.setState({
      notificationData: [],
    });
  }else{
    console.log("Internal Server Error")
  }


}

getLogoutSuccessCallBack(data:any){
    localStorage.removeItem("token")
      localStorage.removeItem("userData")
      localStorage.removeItem("fullName")
      localStorage.removeItem("primaryData")
      localStorage.removeItem("count")
     
      window.location.href = "/emailLogin";
      

      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ action: 'logout' });
      } else {
        console.warn('Service worker controller not found.');
      }
}
notificationAction = (currentIndex: number) => {
  const visibleNotificationAction=this.state.visibleNotificationAction
  visibleNotificationAction[currentIndex]=!visibleNotificationAction[currentIndex]
  this.setState({visibleNotificationAction})
}


 updateCountInContext = (c:any , incrementCount:any) => {
  incrementCount(c + 1);

  this.setState({
    isBell:true,
    isShow:false
  }, ()=>{
    this.notificationAPI() 
  })

  // Remove the "notificationCount" key from localStorage
  localStorage.removeItem('count');
};


  // Customizable Area End
  

}

